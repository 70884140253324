import {Button, message, Modal} from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import fbaDetailImg from '@/assets/images/GetAnInstantCode/amazonNew.png';
import { useTranslation } from 'react-i18next';
import './index.scss';
import './mobile.scss';
import {BASE_URL, getFbaOrWalmartFile} from "@/api";

const defaultProps = {
  isShow: false,
  onModalCancel: () => {
  },
  showGetAnInstantCodeModal: () => {
  },
};
export default function GetAnInstantCodeFBA(props) {
  const options = Object.assign({}, defaultProps, props);
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'get-an-instant-code-fba-mobile-modal' : 'get-an-instant-code-fba-modal'
  const { t } = useTranslation();
  const navigate = useNavigate()
  const handleContactUsClick = () => {
    options.onModalCancel();
    navigate('contact');
  }

  const handleGoBackClick = () => {
    options.onModalCancel();
    options.showGetAnInstantCodeModal();
  }
  const [activeBtnType, setActiveBtnType] = useState('address1')
  const handleQuoteBtnClick = type => {
    setActiveBtnType(type)
    getFbaOrWalmartFile(type).then(response => {
      if (response.data && response.data.pdf_url) {
        window.open(response.data.pdf_url);
      } else {
        message.error(t('instant_war_soon'))
      }
    });
  }

  return (
    <div>
      <Modal
        open={options.isShow}
        footer={null}
        maskClosable={false}
        onCancel={options.onModalCancel}
        className={rootClassName}>
        <div className={'get-an-instant-code-fba-container'}>
          <div className={'get-an-instant-code-fba-left-container'}>
            {/* <div className={'get-an-instant-code-fba-left-title'}>
              {t('instant_fba_quote')}
            </div> */}
            {/* <div className={'get-an-instant-code-fba-left-des'}>
              {t('instant_fba_please')}
              <Button
                type="link"
                onClick={handleContactUsClick}
                className={'get-an-instant-code-fba-link'}>
                {t('instant_fba_contact')}
              </Button>
              {t('instant_fba_information')}
            </div> */}
            {/* <div className={'get-an-instant-code-fba-left-tip'}>
              {t('instant_fba_thank')}
            </div> */}
            <div className={'get-an-instant-code-fba-left-img-container'}>
              <img src={fbaDetailImg} alt="" />
            </div>
          </div>
          <div className='get-an-instant-code-fba-right-container'>
            <div className={'get-an-instant-code-fba-right-top-container'}>
              <div
                className={'get-an-instant-code-fba-right-title'}>
                {t('amazon_FBA')}
              </div>
              <div
                className={'get-an-instant-code-fba-right-title get-an-instant-code-fba-right-title-special'}>
                {t('fba_select_location')}
              </div>
              <Button 
                onClick={() => handleQuoteBtnClick('address1')}
                className={activeBtnType === 'address1' ? 'get-an-instant-code-fba-right-btn-1 get-an-instant-code-fba-right-btn-1-active' : 'get-an-instant-code-fba-right-btn-1'}>
                {t('instant_warehouse_los_angeles')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address2')}
                className={activeBtnType === 'address2' ? 'get-an-instant-code-fba-right-btn-1 get-an-instant-code-fba-right-btn-1-active' : 'get-an-instant-code-fba-right-btn-1'}>
                {t('new_york_or_new_jersey')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address3')}
                className={activeBtnType === 'address3' ? 'get-an-instant-code-fba-right-btn-1 get-an-instant-code-fba-right-btn-1-active' : 'get-an-instant-code-fba-right-btn-1'}>
                {t('instant_dra_chicago')}
              </Button>
            </div>
            <Button
              className={'get-an-instant-code-fba-right-btn-2'}
              onClick={handleGoBackClick}
              ghost>
              {t('instant_fba_last_page')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
